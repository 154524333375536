function Auth () {
  $('#phone_code').on('change', function (e) {
    const params = new URLSearchParams(window.location.search)
    params.set('phone_code', $(this).val())

    window.location = `${window.location.pathname}?${params}`
  })

  $('#phone').on('input', function (e) {
    if (($(this).val().trim().length !== 0)) {
      $('.login-btn').prop('disabled', false)
    } else {
      $('.login-btn').prop('disabled', true)
    }
  })

  function showResend () {
    $('.resend').show()
  }

  setTimeout(showResend, 30000)
}

export default Auth
